<template>
    <div>
        <div>
            <TaskTagInput @add:tag="onAddNewTag" @remove:tag="onRemoveTag"/>
        </div>
        <div>
            <div class="modal_button_container">
                <button class="btn btn-primary ok-btn" @click.stop.prevent="process_ok_btn">OK</button>
                <button class="btn btn-secondary cancel-btn" @click.stop.prevent="cancel">Cancel</button>
            </div>
        </div>
    </div>

</template>
<script setup>
const finalTags = ref([]); 
const show_apply_tags_dialog_state = useShowApplyTagsDialog();

const onAddNewTag = tags => {
  finalTags.value = tags
}

const onRemoveTag = tags => {
  finalTags.value = tags;
}


onMounted(async () => {
    let tasks = [];
    document.querySelectorAll('.task_checkboxes').forEach((elem) => {
        if (elem.checked) {
            tasks.push(elem.value)
        }
    });

    if (tasks.length == 0) {
        dkAlert("You did not select any task!")
    } else {
        showGenericModal();
    }    
});

const cancel = () => {
    show_apply_tags_dialog_state.value = false;
    hideGenericModal();
}

const searchStore = useSearchStore();
const redo_search_state = useRedoSearchState();

const process_ok_btn = async () => {
    let tasks = [];
    document.querySelectorAll('.task_checkboxes').forEach((elem) => {
        if (elem.checked) {
        tasks.push(elem.value)
        }
    });

    // selected_space
    let selected_tasks = tasks.join(',');
    let tags = [];
    if (finalTags.value.length == 0) {
        show_apply_tags_dialog_state.value = false;
        hideGenericModal();
        return;
    }

    finalTags.value.forEach((t) => {
        tags.push(t.name);
    })
    let selected_tags = tags.join(',');
    show_apply_tags_dialog_state.value = false;
    hideGenericModal();

    await showWaitPage();

    let result = await batchTag(selected_tasks, selected_tags);
    let waitTime = tasks.length * 200;
    if (waitTime > 2000) {
        waitTime = 2000;
    }
    if (waitTime < 1000) {
        waitTime = 1000; // Enforce a minimum wait time
    }
    await dummyWait(waitTime); // Wait for the backend to do its thing
    document.getElementById('select_all_task').checked = false;
    searchStore.setSearchPagination({ next_page_key: 0, has_more_results: true })
    searchStore.setCommunitySearchPagination({ next_page_key: 0, has_more_results: true });
    searchStore.setSearchData([]);
    redo_search_state.value = redo_search_state.value + 1;
    hideWaitPage();
}
</script>