export const batchTag = async (selected_tasks, tags) => {
    let url = getUrl(`/api/tasks/batchTag/`);
    const result = await logFetchAJAX(url, {
        method: 'POST',
        body: {
            ids: selected_tasks,
            tags: tags
        }
    });
    return result;

}